import type { ProjectSchema } from '@gitbeaker/core/dist/types/types'
import { useQuery } from '@tanstack/react-query'
import { useHttpClient } from 'src/adapters/HTTPClient'

export function useProjects() {
  const httpClient = useHttpClient()

  return useQuery<Record<string, ProjectSchema>>(
    ['gitlab', 'projects'],
    async () => {
      const { data, headers } = await request({ page: 1 })
      const totalPages = parseInt(headers['x-total-pages'])

      if (totalPages > 1) {
        const otherPages = await Promise.all(
          Array.from({ length: totalPages - 1 }).map(async (_, index) => {
            return (await request({ page: index + 2 })).data
          }),
        )

        otherPages.forEach((response) => {
          data.push(...response)
        })
      }

      return Object.fromEntries(data.map((project) => [project.id, project]))

      function request(options: { page: number }) {
        const { page } = options

        return httpClient.get<ProjectSchema[]>('/projects', {
          params: {
            archived: false,
            per_page: 100,
            page,
            simple: true,
          },
        })
      }
    },
    { staleTime: 60 * 60 * 1000 },
  )
}

export function useProject(projectId: number) {
  const projectsResponse = useProjects()

  return { ...projectsResponse, data: projectsResponse.data?.[projectId] }
}
