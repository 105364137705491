import { ChakraProvider } from '@chakra-ui/react'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import React from 'react'
import ReactDOM from 'react-dom'

import { Env, EnvContext } from './adapters/Env'
import { StorageContext, Storage } from './adapters/Storage'
import { ApiTokenProvider } from './ApiTokenProvider'

import './index.css'
import { App } from './App'
import { setup } from './setup'

const env = Env()
const storage = Storage()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 30, // 30 jours
    },
  },
})
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

setup(env)

ReactDOM.render(
  <React.StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <EnvContext.Provider value={env}>
        <StorageContext.Provider value={storage}>
          <ChakraProvider>
            <ApiTokenProvider>
              <App />
            </ApiTokenProvider>
          </ChakraProvider>
        </StorageContext.Provider>
      </EnvContext.Provider>
    </PersistQueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
