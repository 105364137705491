import classNames from 'classnames'
import { useMemo, useState } from 'react'

import './App.css'
import { CalendarView } from './CalendarView'
import type { Command } from './commandStore/types'
import { EventDetails } from './EventDetails'
import type { TimeSpentEvent } from './eventsStore/types'
import { useRootStore } from './eventsStore/useRootStore'
import { LeftPane } from './LeftPane'

export type TimeSpentEventsMap = Record<string, TimeSpentEvent>

export function App() {
  const store = useRootStore()

  const [openedId, openEvent] = useState<string | null>(null)
  const openedEventStore = useMemo(() => {
    return {
      state: openedId ? store.state[openedId] : null,
      dispatch(command: Omit<Command, 'payload.eventId'>) {
        if (!openedId) return
        store.dispatch({
          ...command,
          payload: { ...command.payload, eventId: openedId },
        } as Command)
      },
    }
  }, [store, openedId])

  return (
    <div
      className={classNames(
        'app-layout',
        openedEventStore.state ? ' -hasOpenedEvent' : '',
      )}
    >
      <LeftPane className="issues-picker" />
      <CalendarView
        className="scheduler-container"
        onOpen={openEvent}
        store={store}
      />
      <EventDetails
        className="issue-modal-container"
        onClose={() => openEvent(null)}
        store={openedEventStore}
      />
    </div>
  )
}
