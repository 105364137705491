/* eslint-disable @typescript-eslint/no-use-before-define */

import type {
  AnimatedProps,
  Interpolation,
  SpringValue,
} from '@react-spring/web'
import { useSpring, animated, to } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { addDays, addMinutes } from 'date-fns'
import { useState, useEffect, useCallback, forwardRef } from 'react'
import type { CSSProperties, ReactNode } from 'react'

import type { WeekCoordinates } from './converter'
import { useConverter } from './converter'
import { useKeyPressed } from './libs/useKeyPressed'

export type AnimatedProp<T> = AnimatedProps<{ x: T }>['x']

export type TimeInterval = {
  start: Date
  end: Date
}

export type RenderEventContentParams = {
  height: Interpolation<number>
  isDragging?: boolean
  isClone?: boolean
}

export type RenderEventContent = (params: RenderEventContentParams) => ReactNode

type BaseEventProps = TimeInterval & {
  render: RenderEventContent
  style?: AnimatedProp<CSSProperties>
}

type EventProps = BaseEventProps & {
  onMoveStart?: () => void
  onMoveEnd?: (newValue: Partial<TimeInterval>) => void
  onClone?: (newValue: TimeInterval) => void
  draggable?: boolean
}

export function Event(props: EventProps) {
  const { coordinates } = useConverter()
  const start = coordinates.dateToWeekCoord(props.start)
  const end = coordinates.dateToWeekCoord(props.end)

  const isOutOfBound = end.dayOfWeek < -7 || start.dayOfWeek > 14
  if (isOutOfBound) return null

  if (props.draggable) return <DraggableEvent {...props} />
  return <SimpleEvent {...props} />
}

function SimpleEvent(props: BaseEventProps) {
  const { start, end, render, style } = props

  const { coordinates } = useConverter()

  const startWC = coordinates.dateToWeekCoord(start)
  const endWC = coordinates.dateToWeekCoord(end)
  const [startPoint] = useWeekCoordinatesSpring(startWC)
  const [endPoint] = useWeekCoordinatesSpring(endWC)

  const height = to([startPoint.y, endPoint.y], (startY, endY) => {
    return endY - startY
  })

  return (
    <DrawEvent startPoint={startPoint} endPoint={endPoint} style={style}>
      {render({ height })}
    </DrawEvent>
  )
}

function DraggableEvent(props: EventProps) {
  const { start, end, onMoveEnd, onMoveStart, onClone, render, style } = props

  const [isDragging, setIsDragging] = useState(false)
  const isCloneMode = useKeyPressed('Alt') && onClone

  const { coordinates, units } = useConverter()

  const startWC = coordinates.dateToWeekCoord(start)
  const endWC = coordinates.dateToWeekCoord(end)
  const startXY = coordinates.dateToCanvas(start)
  const endXY = coordinates.dateToCanvas(end)

  const [startPoint, setStartPoint] = useWeekCoordinatesSpring(startWC)
  const [endPoint, setEndPoint] = useWeekCoordinatesSpring(endWC)

  const height = to([startPoint.y, endPoint.y], (startY, endY) => {
    return endY - startY
  })

  const bind = useDrag(
    (dragEvent) => {
      const { pressed, movement, last, first, event } = dragEvent
      const [deltaX, deltaY] = movement

      event.stopPropagation()
      setIsDragging(!last)

      const offset: WeekCoordinates = coordinates.canvasToWeekCoord(
        {
          x: deltaX,
          y: deltaY,
        },
        { roundValues: true },
      )

      if (pressed) {
        setStartPoint({
          dayOfWeek: startWC.dayOfWeek + offset.dayOfWeek,
          fractionOfDay: startWC.fractionOfDay + offset.fractionOfDay,
        })
        setEndPoint({
          dayOfWeek: endWC.dayOfWeek + offset.dayOfWeek,
          fractionOfDay: endWC.fractionOfDay + offset.fractionOfDay,
        })
      }

      if (first) onMoveStart?.()
      if (!last) return
      if (!offset.dayOfWeek && !offset.fractionOfDay) return

      const applyOffsetToDate = (date: Date) =>
        addMinutes(
          addDays(date, offset.dayOfWeek),
          units.fractionOfDayToMinutes(offset.fractionOfDay),
        )

      const newStartEnd = {
        start: applyOffsetToDate(start),
        end: applyOffsetToDate(end),
      }
      if (isCloneMode) {
        onClone?.(newStartEnd)
        setStartPoint({ ...startWC, immediate: true })
        setEndPoint({ ...endWC, immediate: true })
      } else {
        onMoveEnd?.(newStartEnd)
      }
    },
    { threshold: 4 },
  )

  const renderChildrenParams: RenderEventContentParams = { height, isDragging }

  return (
    <>
      {isCloneMode && isDragging && (
        <DrawEvent
          startPoint={startXY}
          endPoint={endXY}
          style={{
            ...style,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
        >
          {render({ ...renderChildrenParams, isClone: true })}
        </DrawEvent>
      )}

      <DrawEvent
        startPoint={startPoint}
        endPoint={endPoint}
        onMouseDown={(event) => event.stopPropagation()}
        {...bind()}
        style={{
          ...style,
          touchAction: 'none',
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
      >
        {render({ ...renderChildrenParams, isClone: false })}
        <DragHandle
          onDrag={(deltaY) => {
            setIsDragging(true)
            onMoveStart?.()

            const fractionOfDay = Math.max(
              1,
              Math.round(
                endWC.fractionOfDay + units.canvasToFractionOfDay(deltaY),
              ),
            )

            if (fractionOfDay >= startWC.fractionOfDay) {
              setStartPoint({
                fractionOfDay: startWC.fractionOfDay,
              })
              setEndPoint({
                fractionOfDay,
              })
            } else {
              setStartPoint({
                fractionOfDay,
              })
              setEndPoint({
                fractionOfDay: startWC.fractionOfDay,
              })
            }
          }}
          onRelease={(deltaY) => {
            setIsDragging(false)

            const fractionOfDay = Math.round(
              endWC.fractionOfDay + units.canvasToFractionOfDay(deltaY),
            )

            const date = coordinates.weekCoordToDate({
              ...endWC,
              fractionOfDay,
            })

            if (date.getTime() >= start.getTime()) {
              onMoveEnd?.({
                end: date,
              })
            } else {
              onMoveEnd?.({
                start: date,
                end: start,
              })
            }
          }}
        />
      </DrawEvent>
    </>
  )
}

type DragHandleProps = {
  onDrag: (deltaY: number) => void
  onRelease: (deltaY: number) => void
}

function DragHandle(props: DragHandleProps) {
  const { onDrag, onRelease } = props
  const bind = useDrag(
    (values) => {
      const { down, movement, event } = values
      const [, deltaY] = movement
      event.stopPropagation()

      if (down) {
        onDrag(deltaY)
      } else {
        onRelease(deltaY)
      }
    },
    { filterTaps: true, threshold: 4 },
  )

  return (
    <animated.button
      {...bind()}
      style={{
        position: 'absolute',
        bottom: '1px',
        left: '33%',
        right: '67%',
        width: '33%',
        display: 'block',
        cursor: 'row-resize',
        touchAction: 'none',
        padding: 0,
        background: 'transparent',
        border: 0,
        height: '1rem',
        lineHeight: 0,
      }}
    >
      ⠶
    </animated.button>
  )
}

export function useWeekCoordinatesSpring(weekCoordinates: WeekCoordinates) {
  const { coordinates, units } = useConverter()

  const point = coordinates.weekCoordToCanvas(weekCoordinates)

  const [springPoint, system] = useSpring(
    {
      from: { x: point.x, y: point.y },
      config: {
        mass: 0.1,
        tension: 220,
        friction: 10,
      },
    },
    [point.x, point.y],
  )

  useEffect(() => {
    system.start({ x: point.x, y: point.y })
  }, [system, point.y, point.x])

  const setCoordinates = useCallback(
    (newValue: Partial<WeekCoordinates> & { immediate?: boolean }) => {
      const { dayOfWeek, fractionOfDay, immediate } = newValue
      if (dayOfWeek !== undefined) {
        system.start({ immediate, x: units.dayOfWeekToCanvas(dayOfWeek) })
      }
      if (fractionOfDay !== undefined) {
        system.start({
          immediate,
          y: units.fractionOfDayToCanvas(fractionOfDay),
        })
      }
    },
    [system, units],
  )

  return [springPoint, setCoordinates] as const
}

type Point = { x: number; y: number }
type SpringPoint = { x: SpringValue<number>; y: SpringValue<number> }

// eslint-disable-next-line no-undef
type DrawEventProps = AnimatedProps<JSX.IntrinsicElements['div']> & {
  startPoint: SpringPoint | Point
  endPoint: SpringPoint | Point
}
export const DrawEvent = forwardRef<HTMLDivElement, DrawEventProps>(
  function DrawEvent(props, ref) {
    const { startPoint, endPoint, ...divProps } = props

    const { constants } = useConverter()
    const { dayWidth } = constants

    const height = to([startPoint.y, endPoint.y], (startY, endY) => {
      return Math.abs(endY - startY)
    })

    return (
      <animated.div
        {...divProps}
        data-event
        ref={ref}
        style={{
          ...props.style,
          transform: to(
            [startPoint.x, startPoint.y, endPoint.x, endPoint.y],
            (startX, startY, endX, endY) => {
              const x = Math.min(startX, endX)
              const y = Math.min(startY, endY)
              return `translate(${x}px, ${y}px)`
            },
          ),
          position: 'absolute',
          height: to([height], (heightV) => `${heightV}px`),
          width: `${dayWidth}px`,
        }}
      >
        {props.children}
      </animated.div>
    )
  },
)
