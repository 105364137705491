import { Box } from '@chakra-ui/react'
import {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { Event } from './Scheduler'

export const NowIndicator = forwardRef<{ scrollIntoView: () => void }>(
  function NowIndicator(_, ref) {
    const now = useTime()

    const anchor = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => {
      return {
        scrollIntoView() {
          anchor.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        },
      }
    })
    useLayoutEffect(() => {
      anchor.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [])

    return (
      <Event
        start={now}
        end={now}
        draggable={false}
        style={{ zIndex: 20 }}
        render={() => {
          return (
            <Box
              ref={anchor}
              width={'100%'}
              height={1}
              opacity={0.5}
              background={'red'}
              borderRadius={4}
            />
          )
        }}
      />
    )
  },
)

function useTime(refreshCycle = 60 * 1000) {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => setNow(new Date()), refreshCycle)

    return () => clearInterval(intervalId)
  }, [refreshCycle, setNow])

  return now
}
