export function filterObj<T extends Record<string, U>, U = any>(
  obj: T,
  predicate: (val: U, key: keyof T) => any,
): Partial<T> {
  const result: Partial<T> = {}

  for (const key in obj) {
    const value = obj[key]
    if (predicate(value, key)) {
      result[key] = value
    }
  }

  return result
}

/** filters out falsy values from object */
export function compactObj<T extends Record<string, any>>(obj: T): Partial<T> {
  return filterObj(obj, Boolean)
}
