import { Box, Grid, GridItem } from '@chakra-ui/react'
import {
  addDays,
  addMinutes,
  startOfWeek as getStartOfWeek,
  roundToNearestMinutes,
} from 'date-fns'
import getClientXY from 'get-client-xy'
import type {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  MouseEventHandler,
} from 'react'
import { useCallback, useImperativeHandle, forwardRef, useRef } from 'react'

import { Canvas } from './Canvas'
import type { Converter } from './converter'
import { ConverterContext, useCoordinatesSystem } from './converter'
import type { RenderEventContent, TimeInterval } from './Event'

export type SchedulerInstance = {
  mouseEventToDate: (
    event: MouseEvent | TouchEvent | { clientX: number; clientY: number },
  ) => Date | undefined
  converter: ReturnType<typeof Converter>
}

type QuarterPosition = {
  weekday: number
  hour: number
  quarter: number
}

export type TimeGridProps = {
  getQuarterStyle?: (quarterPosition: QuarterPosition) => CSSProperties
  onClick?: MouseEventHandler<HTMLDivElement>
  onMouseMove?: MouseEventHandler<HTMLDivElement>
  renderEventPreview: RenderEventContent
  onCreate: (interval: TimeInterval) => void
  renderDayHeader: (date: Date) => ReactNode
  weekOffset: number
}

const HOURS_AXIS_WIDTH = '3rem'
const DAYS_AXIS_HEIGHT = '4rem'
// const weekOffset = 0

export const TimeGrid = forwardRef<
  SchedulerInstance,
  PropsWithChildren<TimeGridProps>
>(function TimeGrid(props: PropsWithChildren<TimeGridProps>, ref) {
  const {
    onClick,
    onMouseMove,
    getQuarterStyle,
    renderEventPreview,
    renderDayHeader,
    onCreate,
    children,
    weekOffset,
  } = props

  const today = addDays(new Date(), weekOffset * 7)
  const startOfWeek = getStartOfWeek(today, { weekStartsOn: 1 })
  const { converter, observe } = useCoordinatesSystem({ startOfWeek })

  const canvasRef = useRef<HTMLDivElement>(null)

  const mouseEventToDate: SchedulerInstance['mouseEventToDate'] = useCallback(
    (event) => {
      const boundingRect = canvasRef.current?.getBoundingClientRect()
      if (!boundingRect) return

      const [clientX, clientY] = getClientXY(event as any)
      const coords = {
        x: clientX - boundingRect.left,
        y: clientY - boundingRect.top,
      }
      return converter.coordinates.canvasToDate(coords)
    },
    [converter.coordinates],
  )

  useImperativeHandle(
    ref,
    () => {
      return {
        mouseEventToDate,
        converter,
      }
    },
    [converter, mouseEventToDate],
  )

  return (
    <ConverterContext.Provider value={converter}>
      <div
        style={{
          display: 'grid',
          gridTemplateRows: `${DAYS_AXIS_HEIGHT} 1fr`,
          gridTemplateColumns: `${HOURS_AXIS_WIDTH} 1fr`,
          width: '100vw',
          alignItems: 'stretch',
        }}
      >
        <DaysAxis
          firstDayOfWeek={startOfWeek}
          renderDayHeader={renderDayHeader}
        />
        <TimeAxis />
        <Canvas
          onCreate={onCreate}
          renderEventPreview={renderEventPreview}
          onClick={onClick}
          onMouseMove={onMouseMove}
          onDoubleClick={(event) => {
            if (
              someAncestor(event.target as HTMLElement, (element) =>
                element.matches('[data-event]'),
              )
            ) {
              return
            }

            const eventDate = roundToNearestMinutes(mouseEventToDate(event)!, {
              nearestTo: 15,
            })
            if (!eventDate) {
              return
            }

            onCreate({
              start: addMinutes(eventDate, -15),
              end: addMinutes(eventDate, 15),
            })
          }}
          ref={(element) => {
            observe(element)
            ;(canvasRef as any).current = element
          }}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            alignItems: 'stretch',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {Array.from({ length: 7 }).map((_, weekday) => {
            return (
              <Day
                key={weekday}
                getQuarterStyle={getQuarterStyle}
                weekday={weekday}
              />
            )
          })}
          {children}
        </Canvas>
      </div>
    </ConverterContext.Provider>
  )
})

type DayProps = {
  weekday: number
  getQuarterStyle?: (quarterPosition: QuarterPosition) => CSSProperties
}

function Day(props: DayProps) {
  const { weekday, getQuarterStyle } = props

  return (
    <HoursGrid
      style={{
        borderRight: '1px solid black',
      }}
      renderHour={(hour) => {
        return (
          <>
            {Array.from({ length: 4 }).map((_, quarter) => {
              return (
                <div
                  key={quarter}
                  style={{
                    borderTop: '1px solid rgba(100, 100, 100, 0.25)',
                    flex: 1,
                    opacity: 0.5,
                    ...getQuarterStyle?.({
                      weekday,
                      hour,
                      quarter,
                    }),
                  }}
                />
              )
            })}
          </>
        )
      }}
    />
  )
}

type DaysAxisProps = {
  firstDayOfWeek: Date
  renderDayHeader: (date: Date) => ReactNode
}

function DaysAxis(props: DaysAxisProps) {
  const { firstDayOfWeek, renderDayHeader } = props
  return (
    <Grid
      templateColumns={`${HOURS_AXIS_WIDTH} repeat(7, 1fr)`}
      gridColumn={'span 2'}
      position={'sticky'}
      top={'3rem'}
      shadow={'0 8px 16px rgba(0, 0, 0, 0.1)'}
      background={'white'}
      zIndex={30}
    >
      <Box />
      {Array.from({ length: 7 }).map((_, weekday) => (
        <GridItem key={weekday}>
          {renderDayHeader(addDays(firstDayOfWeek, weekday))}
        </GridItem>
      ))}
    </Grid>
  )
}

function TimeAxis() {
  return (
    <HoursGrid
      style={{
        position: 'sticky',
        left: 'var(--panelSize)', // TODO responsive
        background: 'white',
        boxShadow: '8px 0 16px rgba(0, 0, 0, 0.1)',
        zIndex: 20,
      }}
      renderHour={(hour) => {
        return (
          <label
            style={{
              lineHeight: '1rem',
              top: '-.5rem',
              position: 'absolute',
              right: '.25rem',
            }}
          >
            {hour}h
          </label>
        )
      }}
    />
  )
}

type HoursGridProps = {
  renderHour: (hour: number) => ReactNode | undefined
  style?: CSSProperties
}

function HoursGrid(props: HoursGridProps) {
  const { renderHour, style } = props
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        ...style,
      }}
    >
      {Array.from({ length: 24 }).map((_, hour) => {
        return (
          <div
            key={hour}
            style={{
              borderTop: '1px solid lightgrey',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minHeight: '4.5rem',
              position: 'relative',
            }}
          >
            {renderHour(hour)}
          </div>
        )
      })}
    </div>
  )
}

function someAncestor(
  element: HTMLElement,
  predicate: (element: HTMLElement) => boolean,
): boolean {
  if (predicate(element)) {
    return true
  }
  if (!element.parentElement) {
    return false
  }
  return someAncestor(element.parentElement, predicate)
}
