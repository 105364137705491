import {
  ChevronRightIcon,
  ChevronLeftIcon,
  RepeatClockIcon,
} from '@chakra-ui/icons'
import {
  Box,
  ButtonGroup,
  Flex,
  Heading,
  Text,
  IconButton,
  Spacer,
  VStack,
} from '@chakra-ui/react'

import {
  addMinutes,
  addWeeks,
  format,
  formatDuration,
  getWeek,
  isSameDay,
  setHours,
  setMinutes,
} from 'date-fns'
import { fr } from 'date-fns/locale'
import { useState, useRef, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useIdentity } from 'src/adapters/Identity'
import type { TimeSpentEventsMap } from 'src/App'
import {
  newEditCommand,
  newCreateCommand,
  newDeleteCommand,
} from 'src/commandStore'
import type { CommandStore } from 'src/eventsStore/useRootStore'
import { defaultColor } from 'src/Issue/getIssueColor'
import { useEvent } from 'src/libs/useEvent'
import * as uuid from 'uuid'

import type { TimeSpentEvent as ITimeSpentEvent } from '../eventsStore/types'

import {
  computeDailyTimeSpent,
  computeWeeklyTimeSpent,
} from './computeDailyTimeSpent'
import { NowIndicator } from './NowIndicator'
import { Event, TimeGrid } from './Scheduler'
import type { SchedulerInstance } from './Scheduler/TimeGrid'
import { EventBox, TimeSpentEvent } from './TimeSpentEvent'
import { useClipBoardController } from './useClipboardController'

const etienneGitlabUserID = 38

type Props = {
  className?: string
  store: CommandStore<TimeSpentEventsMap>
  onOpen: (eventId: string | null) => void
}

export function CalendarView(props: Props) {
  const { className, store, onOpen } = props
  const { state } = store

  const me = useIdentity()
  const [selectedId, setSelected] = useState<string>()
  const hoveredDate = useRef<Date>()
  const calendar = useRef<SchedulerInstance>(null)

  const onDeleteSelected = useEvent(() => {
    if (!selectedId) return
    const prevEvent = state[selectedId]
    if (!prevEvent) return

    store.dispatch(newDeleteCommand(prevEvent))
  })
  const createEvent = (
    base: Partial<ITimeSpentEvent> &
      Pick<ITimeSpentEvent, 'startDate' | 'endDate'>,
  ) => {
    if (!me) return

    const newEvent: ITimeSpentEvent = {
      ...base,
      id: uuid.v4(),
      gitlabUserId: me.id,
      createdAt: new Date(),
    }

    store.dispatch(newCreateCommand(newEvent))
    setSelected(newEvent.id)
  }

  useClipBoardController({
    getConverter() {
      return calendar.current?.converter
    },
    getHoveredDate() {
      return hoveredDate.current
    },
    state,
    createEvent,
    onDeleteSelected,
    selectedId,
  })

  useHotkeys('Delete', onDeleteSelected)
  useHotkeys('BackSpace', onDeleteSelected)

  const dailyTimeSpent = useMemo(() => {
    return computeDailyTimeSpent(state)
  }, [state])
  const weeklyTimeSpent = useMemo(() => {
    return computeWeeklyTimeSpent(state)
  }, [state])

  const [week, setWeek] = useState(0)
  const selectedWeek = useMemo(() => {
    return getWeek(addWeeks(new Date(), week), { locale: fr })
  }, [week])

  const nowIndicator = useRef<{ scrollIntoView: () => void }>(null)

  return (
    <Box className={className} width={'calc(100vw + var(--panelSize))'}>
      <Flex
        as={'header'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'calc(100vw - var(--panelSize))'}
        position={'sticky'}
        top={0}
        background={'white'}
        zIndex={100}
        left={'var(--panelSize)'}
        paddingX={'4'}
        paddingY={'2'}
      >
        <VStack alignItems={'flex-start'}>
          <Heading size={'md'}>Semaine {selectedWeek}</Heading>
          <Duration timeSpent={weeklyTimeSpent[selectedWeek]} />
        </VStack>
        <Spacer />
        <ButtonGroup>
          <IconButton
            aria-label="Semaine précédente"
            icon={<ChevronLeftIcon />}
            onClick={() => setWeek((x) => x - 1)}
          />
          <IconButton
            aria-label="Semaine précédente"
            icon={<RepeatClockIcon />}
            onClick={() => {
              setTimeout(
                () => {
                  nowIndicator.current?.scrollIntoView()
                },
                week === 0 ? 0 : 1000,
              )
              setWeek(0)
            }}
            variant={week === 0 ? 'ghost' : 'solid'}
          />
          <IconButton
            aria-label="Semaine suivante"
            icon={<ChevronRightIcon />}
            onClick={() => setWeek((x) => x + 1)}
          />
        </ButtonGroup>
      </Flex>
      <div
        onMouseDown={() => {
          setSelected(undefined)
        }}
        onDragOver={(event) => {
          // required to denote this component is droppable
          event.preventDefault()
        }}
        onDrop={(browserEvent) => {
          const dropDate = calendar.current?.mouseEventToDate(browserEvent)
          if (!dropDate) return
          const rounded = roundToNearestQuarter(dropDate)

          const gitlabIssue = JSON.parse(
            browserEvent.dataTransfer.getData('application/json'),
          )
          createEvent({
            gitlabIssue,
            startDate: addMinutes(rounded, -30),
            endDate: addMinutes(rounded, 30),
          })
        }}
        onMouseEnter={(event) => {
          hoveredDate.current = calendar.current?.mouseEventToDate(event)
        }}
        onMouseMove={(event) => {
          hoveredDate.current = calendar.current?.mouseEventToDate(event)
        }}
        onMouseLeave={() => {
          hoveredDate.current = undefined
        }}
      >
        <TimeGrid
          weekOffset={week}
          ref={calendar}
          getQuarterStyle={(quarterTime) => {
            const { weekday, hour } = quarterTime
            const isWeekend = weekday > 4
            if (isWeekend) return { background: 'rgba(0, 0, 0, 0.3)' }
            const isWorkableHour = hour >= 7 && hour < 19
            if (!isWorkableHour) return { background: 'rgba(0, 0, 0, 0.3)' }
            const isMandatoryHour =
              (hour >= 9 && hour < 12) || (hour >= 14 && hour < 17)
            if (isMandatoryHour) return { background: 'white' }
            return { background: 'rgba(0, 0, 0, 0.1)' }
          }}
          renderEventPreview={(params) => {
            const { height } = params
            return (
              <EventBox
                color={defaultColor}
                availableHeight={height}
                isSelected
              />
            )
          }}
          renderDayHeader={(date) => {
            const isToday = isSameDay(date, new Date())

            const timeSpentToday = dailyTimeSpent[date.toDateString()]

            return (
              <Flex
                direction={'column'}
                height={'100%'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={0}
              >
                <Heading
                  as={'h4'}
                  size={'sm'}
                  background={isToday ? 'orange' : 'inherit'}
                  padding="2px 8px"
                  borderRadius={'8'}
                  margin={0}
                >
                  {format(date, 'eee d LLL', { locale: fr })}
                </Heading>
                <Duration timeSpent={timeSpentToday} />
              </Flex>
            )
          }}
          onCreate={(interval) => {
            createEvent({
              startDate: interval.start,
              endDate: interval.end,
            })
          }}
        >
          <NowIndicator ref={nowIndicator} />

          {me?.id === etienneGitlabUserID && (
            <Event
              draggable={false}
              start={setMinutes(setHours(new Date(), 12), 15)}
              end={setMinutes(setHours(new Date(), 13), 45)}
              render={() => {
                return (
                  <EventBox color={'grey'} availableHeight={0}>
                    Wittman + Bière
                  </EventBox>
                )
              }}
            />
          )}

          {Object.values(state).map((event) => {
            const isSelected = selectedId === event.id
            return (
              <TimeSpentEvent
                key={event.id}
                value={event}
                isSelected={isSelected}
                onSelect={() => setSelected(event.id)}
                onOpen={() => onOpen(event.id)}
                onMoveEnd={(newInterval) => {
                  store.dispatch(
                    newEditCommand(event, {
                      startDate: newInterval.start,
                      endDate: newInterval.end,
                    }),
                  )
                }}
                onClone={(interval) => {
                  if (!me) return
                  createEvent({
                    ...event,
                    gitlabUserId: me.id,
                    startDate: interval.start,
                    endDate: interval.end,
                  })
                }}
              />
            )
          })}
        </TimeGrid>
      </div>
    </Box>
  )
}

function Duration(props: { timeSpent?: number }) {
  const { timeSpent } = props
  if (!timeSpent) return null
  const formattedTimeSpent = formatDuration(
    {
      hours: Math.floor(timeSpent / 60),
      minutes: timeSpent % 60,
    },
    { locale: fr },
  )
  return (
    <Text
      fontSize={'0.75rem'}
      marginTop={0}
      opacity={0.5}
      fontWeight={'bold'}
      align={'center'}
      lineHeight={1}
      style={{ margin: 0 }} // wtf emotion
    >
      {formattedTimeSpent}
    </Text>
  )
}

function roundToNearestQuarter(date: Date) {
  const minutes = date.getMinutes()
  return setMinutes(date, Math.round(minutes / 15) * 15)
}
