import { differenceInMinutes } from 'date-fns'
import { useCallback, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { isTruthy } from 'remeda'
import type { TimeSpentEvent } from 'src/eventsStore/types'
import { filterObj } from 'src/libs/filterObj'
import { useLocalStorageState } from 'src/libs/usePersistence'

import { v4 as uuid } from 'uuid'

import type { Command, CommandStatus, TimeSpentDiff } from './types'

export type AddCommandFn = (command: Omit<Command, 'status'>) => void

export function useCommandStore() {
  const [state, setState] = useLocalStorageState<Command[]>(
    'NX_CALENDAR_COMMANDS',
    [],
  )
  const futureCommands = useRef<Command[]>([])

  useHotkeys(
    'ctrl+z,⌘+z',
    () =>
      setState((prevState) => {
        const lastCommand = prevState[prevState.length - 1]
        if (lastCommand?.status === 'created') {
          futureCommands.current.push(lastCommand)
          return prevState.slice(0, -1)
        }

        return prevState
      }),
    [setState],
  )

  useHotkeys(
    'ctrl+⇧+z,⌘+⇧+z',
    () => {
      if (futureCommands.current[futureCommands.current.length - 1]) {
        const redoCommand = futureCommands.current.pop()!
        setState((prevState) => {
          return [...prevState, redoCommand]
        })
      }
    },
    [setState],
  )

  const addCommand = useCallback<AddCommandFn>(
    (command) => {
      futureCommands.current = []
      setState((prevList) => [
        ...prevList,
        { ...command, status: 'created' } as Command,
      ])
    },
    [setState],
  )

  const setCommandsStatus = useCallback(
    (commandsId: string[], status: CommandStatus) => {
      if (!commandsId.length) return
      setState((prevList) => {
        return prevList.map((command) => {
          if (commandsId.includes(command.id)) {
            return { ...command, status }
          }
          return command
        })
      })
    },
    [setState],
  )
  const deleteCommands = useCallback(
    (commandIds: string[]) => {
      if (!commandIds.length) return
      setState((prevList) => {
        return prevList.filter((command) => {
          const shouldDelete = commandIds.includes(command.id)
          return !shouldDelete
        })
      })
    },
    [setState],
  )

  return { commandList: state, addCommand, setCommandsStatus, deleteCommands }
}

export function newCreateCommand(event: TimeSpentEvent): Command {
  let timeSpentDiff
  if (event.gitlabIssue) {
    timeSpentDiff = {
      gitlabIssue: event.gitlabIssue,
      deltaInMinutes: differenceInMinutes(event.endDate, event.startDate, {
        roundingMethod: 'ceil',
      }),
    }
  }
  return {
    id: uuid(),
    type: 'create',
    timestamp: Date.now(),
    status: 'created',
    payload: {
      eventValues: event,
      timeSpentDiffs: [timeSpentDiff].filter(isTruthy),
    },
  }
}

export function newDeleteCommand(event: TimeSpentEvent): Command {
  let timeSpentDiff
  if (event.gitlabIssue) {
    timeSpentDiff = {
      gitlabIssue: event.gitlabIssue,
      deltaInMinutes:
        -1 *
        differenceInMinutes(event.endDate, event.startDate, {
          roundingMethod: 'ceil',
        }),
    }
  }
  return {
    id: uuid(),
    type: 'delete',
    timestamp: Date.now(),
    payload: {
      eventId: event.id,
      timeSpentDiffs: [timeSpentDiff].filter(isTruthy),
    },
    status: 'created',
  }
}

export function newEditCommand(
  event: TimeSpentEvent,
  newEventValue: Partial<TimeSpentEvent>,
): Command {
  const timeSpentDiffs = getTimeSpentDiff(event, newEventValue)

  return {
    id: uuid(),
    timestamp: Date.now(),
    type: 'edit',
    status: 'created',
    payload: {
      eventId: event.id,
      eventValues: filterObj(newEventValue, (val) => val !== undefined),
      timeSpentDiffs,
    },
  }
}

function getTimeSpentDiff(
  event: TimeSpentEvent,
  newEventValue: Partial<TimeSpentEvent>,
): TimeSpentDiff[] {
  if (!event.gitlabIssue && newEventValue.gitlabIssue) {
    return [
      {
        gitlabIssue: newEventValue.gitlabIssue,
        deltaInMinutes: differenceInMinutes(
          newEventValue.endDate ?? event.endDate,
          newEventValue.startDate ?? event.startDate,
          {
            roundingMethod: 'ceil',
          },
        ),
      },
    ]
  }

  if (event.gitlabIssue) {
    if (newEventValue.gitlabIssue === null) {
      return [
        {
          gitlabIssue: event.gitlabIssue,
          deltaInMinutes:
            -1 *
            differenceInMinutes(event.endDate, event.startDate, {
              roundingMethod: 'ceil',
            }),
        },
      ]
    }

    if (
      newEventValue.gitlabIssue &&
      newEventValue.gitlabIssue.id !== event.gitlabIssue.id
    ) {
      return [
        {
          gitlabIssue: event.gitlabIssue,
          deltaInMinutes:
            -1 *
            differenceInMinutes(event.endDate, event.startDate, {
              roundingMethod: 'ceil',
            }),
        },
        {
          gitlabIssue: newEventValue.gitlabIssue,
          deltaInMinutes: differenceInMinutes(
            newEventValue.endDate ?? event.endDate,
            newEventValue.startDate ?? event.startDate,
            {
              roundingMethod: 'ceil',
            },
          ),
        },
      ]
    }

    if (newEventValue.startDate || newEventValue.endDate) {
      const prevDuration = differenceInMinutes(event.endDate, event.startDate, {
        roundingMethod: 'ceil',
      })
      const newDuration = differenceInMinutes(
        newEventValue.endDate ?? event.endDate,
        newEventValue.startDate ?? event.startDate,
        {
          roundingMethod: 'ceil',
        },
      )

      return [
        {
          gitlabIssue: event.gitlabIssue,
          deltaInMinutes: newDuration - prevDuration,
        },
      ]
    }
  }

  return []
}
