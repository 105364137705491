import { Link, Box, VStack } from '@chakra-ui/react'
import type { IssueSchema } from '@gitbeaker/core/dist/types/types'
import ellipsize from 'ellipsize'
import type { HTMLAttributes } from 'react'
import { Remark } from 'react-remark'
import { EventBox } from 'src/CalendarView/TimeSpentEvent'
import type { ConsiseIssueSchema } from 'src/eventsStore/types'

import { useProject } from 'src/useProjects'

import { getIssueColor } from './getIssueColor'
import './styles.css'

type IssueProps = {
  issue: ConsiseIssueSchema | IssueSchema
  compact?: boolean
  draggable?: boolean
} & HTMLAttributes<HTMLElement>

export function Issue(props: IssueProps) {
  const { issue, compact, draggable, ...domProps } = props
  const { project_id, iid } = issue

  const title = 'title' in issue ? issue.title : '-'
  const description = 'description' in issue ? issue.description : '-'
  const web_url = 'web_url' in issue ? issue.web_url : '-'

  const color = getIssueColor(issue)
  const { data: project } = useProject(project_id)

  const projectName = project?.name_with_namespace.replace('Nartex /', '')

  const formatedDescription: string | undefined = description?.replaceAll(
    '#!===============================================================!#',
    '',
  )

  return (
    // Extra box to prevent EventBox to take 100% of the height
    <Box {...domProps}>
      <EventBox color={color} draggable={draggable}>
        <VStack alignItems={'flex-start'} paddingY={2} spacing={0}>
          <Link
            as={'a'}
            className="caption"
            href={web_url}
            fontSize={'xs'}
            opacity={0.8}
            target="_blank"
            rel="noreferrer"
          >
            {projectName || project_id} — #{iid}
          </Link>
          <strong className="title">{title}</strong>
          <div className="content">
            {compact ? (
              ellipsize(formatedDescription ?? '', 100, { ellipse: '…' })
            ) : (
              <Remark>{formatedDescription}</Remark>
            )}
          </div>
        </VStack>
      </EventBox>
    </Box>
  )
}
