import { Flex, Box } from '@chakra-ui/react'
import type { Interpolation } from '@react-spring/web'
import { forwardRef } from 'react'
import { getIssueColor } from 'src/Issue/getIssueColor'
import { useGitlabIssue } from 'src/useGitlabIssue'

import type { TimeSpentEvent as ITimeSpentEvent } from '../eventsStore/types'

import type { TimeInterval } from './Scheduler'
import { Event } from './Scheduler'

type TimeSpentEventProps = {
  value: ITimeSpentEvent
  isSelected?: boolean
  onSelect: () => void
  onOpen: () => void
  onMoveEnd: (interval: Partial<TimeInterval>) => void
  onClone: (interval: TimeInterval) => void
}
export function TimeSpentEvent(props: TimeSpentEventProps) {
  const { value, isSelected, onSelect, onOpen, onMoveEnd, onClone } = props

  const { data: gitlabIssue } = useGitlabIssue(value.gitlabIssue)

  return (
    <Event
      start={new Date(value.startDate)}
      end={new Date(value.endDate)}
      onMoveStart={onSelect}
      onMoveEnd={onMoveEnd}
      onClone={onClone}
      draggable
      style={{
        zIndex: isSelected ? 10 : 5,
        padding: '1px 0.25rem',
      }}
      render={(params) => {
        const { isDragging, isClone } = params

        return (
          <EventBox
            onMouseDown={onSelect}
            onDoubleClick={onOpen}
            color={getIssueColor(value.gitlabIssue)}
            isSelected={isSelected && !isClone}
            isElevated={isDragging}
          >
            {value.name || gitlabIssue?.title}
          </EventBox>
        )
      }}
    />
  )
}

// eslint-disable-next-line no-undef
type EventBoxProps = JSX.IntrinsicElements['div'] & {
  availableHeight?: Interpolation<number, any> | number
  isSelected?: boolean
  isElevated?: boolean
  color: string
}
export const EventBox = forwardRef<HTMLDivElement, EventBoxProps>(
  function EventBox(props, ref) {
    const { isSelected, isElevated, color, children, draggable, ...divProps } =
      props

    return (
      <Flex
        {...divProps}
        draggable={draggable}
        cursor={draggable ? 'grab' : 'inherit'}
        ref={ref}
        padding={0.5}
        borderRadius={'md'}
        background={'white'}
        opacity={0.75}
        color={'blackAlpha.900'}
        fontWeight={600}
        border={
          isSelected
            ? '2px solid rgba(0, 0, 0, 0.8)'
            : '2px solid rgba(100, 100, 100, 0.4)'
        }
        height={'full'}
        userSelect={'none'}
        transition={'.1s ease-out'}
        position={'relative'}
        boxShadow={isElevated ? '8px 8px 16px rgba(0, 0, 0, 0.1)' : ''}
        overflow={'hidden'}
        alignItems={'stretch'}
        gap={1}
      >
        <Box
          flex={0}
          borderLeft={`0.5rem solid ${color}`}
          borderRadius={'1rem'}
        />
        <Box flex={1}>{children}</Box>
      </Flex>
    )
  },
)
