import type { ConsiseIssueSchema } from 'src/eventsStore/types'
import uniqolor from 'uniqolor'

export const defaultColor = '#a7259f' // pour faire plaisir à Solène

export function getIssueColor(issue: ConsiseIssueSchema | null | undefined) {
  if (!issue?.project_id) return defaultColor
  const { color } = uniqolor(issue?.project_id, {
    saturation: [35, 70],
    lightness: 40,
  })

  return color
}
