import { useEffect } from 'react'
import type { TimeSpentEventsMap } from 'src/App'
import { useEvent } from 'src/libs/useEvent'
import superjson from 'superjson'

import type { TimeSpentEvent as ITimeSpentEvent } from '../eventsStore/types'

import type { SchedulerInstance } from './Scheduler/TimeGrid'

function useClipboardEventListener(
  eventName: 'copy' | 'cut' | 'paste',
  _callback: (event: ClipboardEvent) => void,
) {
  const callback = useEvent(_callback)
  useEffect(() => {
    document.body.addEventListener(eventName, callback)
    return () => {
      document.body.removeEventListener(eventName, callback)
    }
  }, [eventName, callback])
}

type UseClipBoardParams = {
  selectedId?: string
  state: TimeSpentEventsMap
  getConverter: () => SchedulerInstance['converter'] | undefined
  getHoveredDate: () => Date | undefined
  createEvent: (newEvent: ITimeSpentEvent) => void
  onDeleteSelected: () => void
}
export function useClipBoardController(params: UseClipBoardParams) {
  const {
    selectedId,
    state,
    getConverter,
    getHoveredDate,
    onDeleteSelected,
    createEvent,
  } = params

  const onCopySelected = useEvent((event: ClipboardEvent) => {
    if (!selectedId) return
    const prevEvent = state[selectedId]
    if (!prevEvent) return

    const selection = document.getSelection()
    if (!selection?.isCollapsed) return

    event.clipboardData?.setData('nxcalendar', superjson.stringify(prevEvent))
    event.preventDefault()
  })

  const onPaste = useEvent((event: ClipboardEvent) => {
    const converter = getConverter()
    const hoveredDate = getHoveredDate()
    if (!converter) return
    const data = event.clipboardData?.getData('nxcalendar')
    if (!data) return

    // try {
    const newEvent = superjson.parse<ITimeSpentEvent>(data)
    if (!newEvent) return

    if (!hoveredDate) {
      // TBD
      return
    }

    const coordinatesConverter = converter.coordinates
    const hoveredWeekCoord = coordinatesConverter.dateToWeekCoord(hoveredDate)
    const startWeekCoord = coordinatesConverter.dateToWeekCoord(
      newEvent.startDate,
    )
    const endWeekCoord = coordinatesConverter.dateToWeekCoord(newEvent.endDate)

    if (hoveredWeekCoord.dayOfWeek === startWeekCoord.dayOfWeek) {
      const duration = endWeekCoord.fractionOfDay - startWeekCoord.fractionOfDay
      createEvent({
        ...newEvent,
        startDate: coordinatesConverter.weekCoordToDate({
          ...startWeekCoord,
          fractionOfDay: Math.round(hoveredWeekCoord.fractionOfDay),
        }),
        endDate: coordinatesConverter.weekCoordToDate({
          ...endWeekCoord,
          fractionOfDay: Math.round(hoveredWeekCoord.fractionOfDay + duration),
        }),
      })
    } else {
      createEvent({
        ...newEvent,
        startDate: coordinatesConverter.weekCoordToDate({
          ...startWeekCoord,
          dayOfWeek: hoveredWeekCoord.dayOfWeek,
        }),
        endDate: coordinatesConverter.weekCoordToDate({
          ...endWeekCoord,
          dayOfWeek: hoveredWeekCoord.dayOfWeek,
        }),
      })
    }
  })

  useClipboardEventListener('copy', (event) => {
    onCopySelected(event)
  })

  useClipboardEventListener('cut', (event) => {
    onCopySelected(event)
    onDeleteSelected()
  })

  useClipboardEventListener('paste', onPaste)
}
