import { differenceInMinutes, getWeek } from 'date-fns'
import { fr } from 'date-fns/locale'
import type { TimeSpentEventsMap } from 'src/App'

export function computeDailyTimeSpent(events: TimeSpentEventsMap) {
  const dailyDurations: Record<string, number | undefined> = {}

  Object.values(events)
    .filter((event) => event.startDate && event.endDate) // prevents potential corrupted values
    .forEach((event) => {
      const duration = differenceInMinutes(
        new Date(event.endDate),
        new Date(event.startDate),
      )
      const day = new Date(event.startDate).toDateString()
      dailyDurations[day] = (dailyDurations[day] ?? 0) + duration
    })

  return dailyDurations
}

export function computeWeeklyTimeSpent(events: TimeSpentEventsMap) {
  const weeklyDurations: Record<string, number | undefined> = {}

  Object.values(events)
    .filter((event) => event.startDate && event.endDate) // prevents potential corrupted values
    .forEach((event) => {
      const duration = differenceInMinutes(
        new Date(event.endDate),
        new Date(event.startDate),
      )

      // Year should be taken into account but I'm lazy
      const week = getWeek(event.startDate, {
        locale: fr,
      })
      weeklyDurations[week] = (weeklyDurations[week] ?? 0) + duration
    })

  return weeklyDurations
}
