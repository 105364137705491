import type { IssueSchema } from '@gitbeaker/core/dist/types/types'
import { useQuery } from '@tanstack/react-query'

import { useHttpClient } from './adapters/HTTPClient'
import type { ConsiseIssueSchema } from './eventsStore/types'

export function useGitlabIssue(gitlabIssue?: ConsiseIssueSchema | null) {
  const httpClient = useHttpClient()
  return useQuery({
    queryKey: ['gitlab', 'issue', gitlabIssue?.id],
    async queryFn() {
      const { data: result } = await httpClient.get<IssueSchema>(
        `/projects/${gitlabIssue!.project_id}/issues/${gitlabIssue!.iid}`,
      )
      return result
    },
    enabled: Boolean(gitlabIssue),
  })
}
