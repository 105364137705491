import { forwardRef } from '@chakra-ui/react'
import type { IssueSchema } from '@gitbeaker/core/dist/types/types'
import classNames from 'classnames'
import { useRef, useEffect, useCallback, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { useHotkeys } from 'react-hotkeys-hook'
import { newDeleteCommand, newEditCommand } from 'src/commandStore'
import type { TimeSpentEvent } from 'src/eventsStore/types'
import type { CommandStore } from 'src/eventsStore/useRootStore'
import { Issue } from 'src/Issue'
import { useEvent } from 'src/libs/useEvent'
import { useGitlabIssue } from 'src/useGitlabIssue'

import './style.css'

type Props = {
  className?: string
  onClose: () => void
  store: CommandStore<TimeSpentEvent | null>
}

export function EventDetails(props: Props) {
  const { className, onClose, store } = props
  const { state } = store

  const overlayRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLElement>(null)
  const { data: gitlabIssue } = useGitlabIssue(state?.gitlabIssue)

  const setState = useCallback(
    (newValue: Partial<TimeSpentEvent>) => {
      if (store.state) {
        return store.dispatch(newEditCommand(store.state, newValue))
      }
    },
    [store],
  )

  const onDelete = useCallback(() => {
    if (store.state) {
      return store.dispatch(newDeleteCommand(store.state))
    }
  }, [store])

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 100)
      selectElementContents(inputRef.current)
    }
  }, [state?.id])

  useHotkeys('escape', onClose, [onClose])

  return (
    <div
      ref={overlayRef}
      className={classNames(className, 'issue-details')}
      onClick={(clickEvent) => {
        if (clickEvent.target === overlayRef.current) {
          onClose()
        }
      }}
      onDragOver={(dragEvent) => {
        if (state !== undefined) {
          // required to denote this component is droppable
          dragEvent.preventDefault()
        }
      }}
      onDrop={(browserEvent) => {
        const issue: IssueSchema = JSON.parse(
          browserEvent.dataTransfer?.getData('application/json'),
        )
        setState({
          gitlabIssue: issue,
        })
      }}
    >
      {state && (
        <div className="content">
          <button
            type="button"
            className="button-delete"
            title="Supprimer"
            onClick={() => {
              onDelete()
              onClose()
            }}
          >
            🚮
          </button>
          <TitleInput
            ref={inputRef}
            value={state.name ?? gitlabIssue?.title ?? ''}
            onChange={(value) => {
              setState({ name: value })
            }}
            onClose={() => {
              onClose()
            }}
          />

          {state.gitlabIssue ? (
            <div className="issue-wrapper">
              <Issue issue={state.gitlabIssue} />
              <button
                type="button"
                className="button-delete"
                title="Supprimer"
                onClick={() =>
                  setState({
                    gitlabIssue: null,
                  })
                }
              >
                🚮
              </button>
            </div>
          ) : (
            <small>
              Glisser un ticket ici pour l{`'`}ajouter à cet évènement
            </small>
          )}
        </div>
      )}
    </div>
  )
}

function selectElementContents(el: HTMLElement) {
  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel?.removeAllRanges()
  sel?.addRange(range)
}

type TitleInputProps = {
  value?: string
  onChange: (value?: string) => void
  onClose: () => void
}
const TitleInput = forwardRef<TitleInputProps, any>(function TitleInput(
  props,
  ref,
) {
  const { onChange, onClose, value } = props

  const [localValue, setLocalValue] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const displayedValue = isFocused ? localValue : value

  return (
    <ContentEditable
      tagName="h2"
      innerRef={ref as any}
      onChange={(domEvent) => {
        setLocalValue((domEvent.target as any).value)
      }}
      // useEvent required because of the underlying implementation that ignores new callbacks
      onFocus={useEvent(() => {
        setLocalValue(value)
        setIsFocused(true)
      })}
      onBlur={(domEvent) => {
        setIsFocused(false)
        onChange((domEvent.target as any).innerText)
      }}
      onKeyUpCapture={(domEvent) => {
        if (domEvent.key === 'Escape') {
          onClose()
        }
        if (domEvent.key === 'Enter' && !domEvent.shiftKey) {
          onChange((domEvent.target as any).innerText)
          onClose()
        }
      }}
      html={displayedValue ?? ''}
    />
  )
})
