import { addDays, addMinutes, differenceInDays } from 'date-fns'
import { createContext, useContext, useMemo } from 'react'
import useDimensions from 'react-cool-dimensions'

export type WeekCoordinates = {
  dayOfWeek: number
  fractionOfDay: number
}

export type CanvasCordinates = {
  x: number
  y: number
}

type ConverterConfig = {
  fractionsPerDay: number
  startOfWeek: Date
  canvasSize: { width: number; height: number }
  canvasOrigin?: { x: number; y: number }
}

const MINUTES_PER_DAY = 60 * 24
export function Converter(config: ConverterConfig) {
  const {
    fractionsPerDay,
    startOfWeek,
    canvasSize,
    canvasOrigin = { x: 0, y: 0 },
  } = config

  const { width, height } = canvasSize

  const constants = {
    fractionHeight: height / fractionsPerDay,
    dayWidth: width / 7,
    fractionDuration: MINUTES_PER_DAY / fractionsPerDay,
  }

  const coordinates = {
    dateToWeekCoord(date: Date): WeekCoordinates {
      const minutes = date.getHours() * 60 + date.getMinutes()
      return {
        dayOfWeek: differenceInDays(date, startOfWeek),
        fractionOfDay: minutes / constants.fractionDuration,
      }
    },
    weekCoordToDate(coords: WeekCoordinates): Date {
      return addMinutes(
        addDays(startOfWeek, coords.dayOfWeek),
        coords.fractionOfDay * constants.fractionDuration,
      )
    },
    weekCoordToCanvas(coords: WeekCoordinates): CanvasCordinates {
      return {
        x: units.dayOfWeekToCanvas(coords.dayOfWeek),
        y: units.fractionOfDayToCanvas(coords.fractionOfDay),
      }
    },
    canvasToWeekCoord(
      coords: CanvasCordinates,
      options?: { roundValues?: boolean },
    ): WeekCoordinates {
      const weekCoords = {
        dayOfWeek: units.canvasToDayOfWeek(coords.x),
        fractionOfDay: units.canvasToFractionOfDay(coords.y),
      }

      if (options?.roundValues) {
        return {
          dayOfWeek: Math.round(weekCoords.dayOfWeek),
          fractionOfDay: Math.round(weekCoords.fractionOfDay),
        }
      }

      return weekCoords
    },
    dateToCanvas(date: Date): CanvasCordinates {
      return coordinates.weekCoordToCanvas(coordinates.dateToWeekCoord(date))
    },
    canvasToDate(coords: CanvasCordinates): Date {
      return coordinates.weekCoordToDate(coordinates.canvasToWeekCoord(coords))
    },
  }

  const units = {
    canvasToFractionOfDay(y: number) {
      return y / constants.fractionHeight - canvasOrigin.y
    },
    canvasToDayOfWeek(x: number) {
      return x / constants.dayWidth - canvasOrigin.x
    },
    dayOfWeekToCanvas(dayOfWeek: number) {
      return constants.dayWidth * dayOfWeek + canvasOrigin.x
    },
    fractionOfDayToCanvas(fractionOfDay: number) {
      return constants.fractionHeight * fractionOfDay + canvasOrigin.y
    },
    fractionOfDayToMinutes(fractionOfDay: number) {
      return constants.fractionDuration * fractionOfDay
    },
  }

  return { coordinates, units, constants }
}

export const ConverterContext = createContext<
  ReturnType<typeof Converter> | undefined
>(undefined)

export function useConverter() {
  const resolved = useContext(ConverterContext)
  if (!resolved) {
    throw Error("Please provide a context for 'Converter'")
  }
  return resolved
}

type CoordinateSystemParams = {
  startOfWeek: Date
}
export function useCoordinatesSystem(params: CoordinateSystemParams) {
  const { startOfWeek } = params

  const { observe, width, height } = useDimensions()

  return {
    converter: useMemo(() => {
      return Converter({
        canvasSize: { width, height },
        fractionsPerDay: 12 * 24, // every 5 minutes
        startOfWeek,
      })
    }, [height, startOfWeek, width]),
    observe,
  }
}
