import { to } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { forwardRef, useRef, useState } from 'react'
import type { Rect } from 'react-use-rect'
import { useRect, useWindowOn } from 'react-use-rect'
import { useMergeRefs } from 'use-callback-ref'

import type { WeekCoordinates } from './converter'
import { useConverter } from './converter'
import type { RenderEventContent, TimeInterval } from './Event'
import { DrawEvent, useWeekCoordinatesSpring } from './Event'

// eslint-disable-next-line no-undef
type DivProps = JSX.IntrinsicElements['div']
type CanvasProps = DivProps & {
  renderEventPreview: RenderEventContent
  onCreate: (timeInterval: TimeInterval) => void
}
export const Canvas = forwardRef<HTMLDivElement, CanvasProps>(function Canvas(
  props,
  forwardedRef,
) {
  const { children, renderEventPreview, onCreate, ...divProps } = props

  const [ref, rect] = useElementRect()

  const { coordinates, constants } = useConverter()
  const [isCreating, setIsCreating] = useState(false)
  const [startPoint, setStartPoint] = useWeekCoordinatesSpring({
    dayOfWeek: 0,
    fractionOfDay: 0,
  })
  const [endPoint, setEndPoint] = useWeekCoordinatesSpring({
    dayOfWeek: 0,
    fractionOfDay: 0,
  })

  const startWCRef = useRef<WeekCoordinates>({ dayOfWeek: 0, fractionOfDay: 0 })
  const bind = useDrag(
    (dragEvent) => {
      const { first, last, initial, values, event } = dragEvent

      event.preventDefault()
      event.stopPropagation()

      if (!rect.current) return
      const { top, left } = rect.current

      if (first) {
        setIsCreating(true)
        startWCRef.current = coordinates.canvasToWeekCoord(
          {
            x: initial[0] - left - constants.dayWidth / 2,
            y: initial[1] - top,
          },
          { roundValues: true },
        )
        setStartPoint({
          ...startWCRef.current,
          immediate: true,
        })
        setEndPoint({
          ...startWCRef.current,
          immediate: true,
        })
      }

      const endWC = coordinates.canvasToWeekCoord(
        {
          x: values[0] - left - constants.dayWidth / 2,
          y: values[1] - top,
        },
        { roundValues: true },
      )
      setEndPoint(endWC)

      if (last) {
        setIsCreating(false)

        const dateA = coordinates.weekCoordToDate(startWCRef.current)
        const dateB = coordinates.weekCoordToDate(endWC)
        if (dateA.getTime() <= dateB.getTime()) {
          onCreate({
            start: dateA,
            end: dateB,
          })
        } else {
          onCreate({
            start: dateB,
            end: dateA,
          })
        }
      }
    },
    { threshold: 4 },
  )

  return (
    <div
      ref={useMergeRefs([forwardedRef, ref])}
      {...divProps}
      {...bind(divProps)}
      style={{ ...divProps.style, touchAction: 'none' }}
    >
      {children}
      {isCreating && (
        <DrawEvent
          startPoint={startPoint}
          endPoint={endPoint}
          style={{ zIndex: 10 }}
        >
          {renderEventPreview({ height: to([10], () => 10), isDragging: true })}
        </DrawEvent>
      )}
    </div>
  )
})

function useElementRect() {
  const positionRef = useRef<Rect | null>(null)
  const [elementRef, revalidate] = useRect(
    (rect) => (positionRef.current = rect),
  )
  useWindowOn('scroll', () => revalidate())

  return [elementRef, positionRef] as const
}
